<template>
  <main class="">
    <div class="horiz-container">
      <div class="business">
        <div class="business-banner">
          <h1 class="business-banner-title">
            <p class="">платформа<img src="../../assets/img/business-banner1.svg" alt=""></p>
            <p class=""><img src="../../assets/img/business-banner2.svg" alt="">менторинга</p>
            <p class="">для сотрудников<br class="br-title"><img src="../../assets/img/business-banner3.svg" alt=""></p>
          </h1>
          <p class="business-banner-subtitle">Развивайте знания сотрудников через обучение и практику!</p>
          <a href="#" @click="onClick" class="base-button big">
            <span>попробовать платформу</span>
            <span class="bg"> </span>
            <span class="slice slice-1"></span>
            <span class="slice slice-2"></span>
          </a>
        </div>

        <FeedbackModal :modal-data="feedbackModalData"/>

      </div>
    </div>
    <section class="ticker">
      <div class="ticker-wrapper">
        <marquee class="items" BEHAVIOR="alternate" SCROLLAMOUNT="6">
          <div class="item"><a href="">ВНУТРЕННЕЕ ОБУЧЕНИЕ</a></div>
          <div class="item"><a href="">ВЫСОКАЯ КОНКУРЕНЦИЯ</a></div>
          <div class="item"><a href="">НЕХВАТКА КАДРОВ</a></div>
          <div class="item"><a href="">НЕОПЫТНЫЕ СПЕЦИАЛИСТЫ</a></div>
          <div class="item"><a href="">УДАЛЕННЫЕ СОТРУДНИКИ</a></div>
          <div class="item"><a href="">ВНУТРЕННЕЕ ОБУЧЕНИЕ</a></div>
          <div class="item"><a href="">ВЫСОКАЯ КОНКУРЕНЦИЯ</a></div>
          <div class="item"><a href="">НЕХВАТКА КАДРОВ</a></div>
          <div class="item"><a href="">НЕОПЫТНЫЕ СПЕЦИАЛИСТЫ</a></div>
          <div class="item"><a href="">УДАЛЕННЫЕ СОТРУДНИКИ</a></div>
          <div class="item"><a href="">ВНУТРЕННЕЕ ОБУЧЕНИЕ</a></div>
          <div class="item"><a href="">ВЫСОКАЯ КОНКУРЕНЦИЯ</a></div>
          <div class="item"><a href="">НЕХВАТКА КАДРОВ</a></div>
          <div class="item"><a href="">НЕОПЫТНЫЕ СПЕЦИАЛИСТЫ</a></div>
          <div class="item"><a href="">УДАЛЕННЫЕ СОТРУДНИКИ</a></div>
          <div class="item"><a href="">ВНУТРЕННЕЕ ОБУЧЕНИЕ</a></div>
          <div class="item"><a href="">ВЫСОКАЯ КОНКУРЕНЦИЯ</a></div>
          <div class="item"><a href="">НЕХВАТКА КАДРОВ</a></div>
          <div class="item"><a href="">НЕОПЫТНЫЕ СПЕЦИАЛИСТЫ</a></div>
          <div class="item"><a href="">УДАЛЕННЫЕ СОТРУДНИКИ</a></div>
        </marquee>
      </div>
    </section>
    <div class="horiz-container">
      <div class="business">
        <section class="business-tasks">
          <div class="business-tasks-title">
            <h2>Решение задач</h2>
            <a href="https://t.me/Gusi_lebedi_com" class="base-button" target="_blank">
              <span>написать в телеграм</span>
              <span class="bg"></span>
              <span class="slice slice-1"></span>
              <span class="slice slice-2"></span>
            </a>
          </div>
          <div class="business-tasks-blocks">
            <div class="business-tasks-block">
              <img src="../../assets/img/tasks1.svg" alt="">
              <p>Сокращение текучки в компании</p>
              <span>01</span>
            </div>
            <div class="business-tasks-block">
              <img src="../../assets/img/tasks2.svg" alt="">
              <p>Повышение уровня компетенции специалистов</p>
              <span>02</span>
            </div>
            <div class="business-tasks-block">
              <img src="../../assets/img/tasks3.svg" alt="">
              <p>Оптимизация времени обучения сотрудников</p>
              <span>03</span>
            </div>
            <div class="business-tasks-block">
              <img src="../../assets/img/tasks4.svg" alt="">
              <p>Мотивация персонала</p>
              <span>04</span>
            </div>
            <div class="business-tasks-block">
              <img src="../../assets/img/tasks5.svg" alt="">
              <p>Обучение новым навыкам</p>
              <span>05</span>
            </div>
          </div>
        </section>
        <section class="business-capability">
          <div class="business-capability-title">
            <h2>возможности платформы</h2>
            <a href="#form" class="base-button">
              <span>внедрить решение</span>
              <span class="bg"></span>
              <span class="slice slice-1"></span>
              <span class="slice slice-2"></span>
            </a>
          </div>
          <div class="business-capability-blocks">
            <div class="business-capability-block">
              <img src="../../assets/img/capability1.svg" alt="">
              <p>Встроенная<br> мотивация</p>
            </div>
            <div class="business-capability-block">
              <img src="../../assets/img/capability2.svg" alt="">
              <p>Сохранение<br> записей в ЛК</p>
            </div>
            <div class="business-capability-block">
              <img src="../../assets/img/capability3.svg" alt="">
              <p>Инструменты<br> для анализа процесса</p>
            </div>
            <div class="business-capability-block">
              <img src="../../assets/img/capability4.svg" alt="">
              <p>Свой видеохостинг,<br> встроенный в платформу</p>
            </div>
            <div class="business-capability-block">
              <img src="../../assets/img/capability5.svg" alt="">
              <p>Конструктор<br> домашнего задания</p>
            </div>
            <div class="business-capability-block">
              <img src="../../assets/img/capability6.svg" alt="">
              <p>Обучающие проекты<br> для учеников</p>
            </div>
          </div>
        </section>
        <section class="business-education">
          <div class="business-education-title">
            <h2>Обучение сотрудников<br> простое и эффективное</h2>
          </div>
          <div class="business-education-content">
            <div class="business-education-content-item">
              <span>1</span>
              <p>Поиск<br> ментора</p>
            </div>
            <div class="business-education-content-item">
              <span>2</span>
              <p>Менторинг<br> сессия</p>
              <img class="business-education-arrow" src="../../assets/img/line-education.svg" alt="">
            </div>
            <div class="business-education-content-item">
              <span>3</span>
              <p>Домашнее<br> задание</p>
              <img class="business-education-arrow" src="../../assets/img/line-education2.svg" alt="">
            </div>
            <div class="business-education-content-item">
              <span>4</span>
              <p>Аналитика</p>
              <img class="business-education-arrow" src="../../assets/img/line-education.svg" alt="">

            </div>

          </div>
        </section>
        <section class="business-screencast">
          <h2 class="business-screencast-title">Скринкаст платформы</h2>
          <iframe src="https://vk.com/video_ext.php?oid=-221696705&id=456239017&hd=2" width="853" height="480" allow="encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
        </section>
        <section class="business-options">
          <h2 class="business-options-title">варианты успешной реализации</h2>
          <div class="business-options-blocks">
            <div class="business-options-blocks-item">
              <div class="front">
                <img src="../../assets/img/options1.svg" alt="">
                <p>Структурирование процесса менторинга в банке</p>
              </div>
              <div class="back yellow">
                <div class="back-item">
                  <p class="back-item-title">Сфера</p>
                  <p class="back-item-subtitle">fintech</p>
                </div>
                <div class="back-item">
                  <p class="back-item-title">Задача</p>
                  <p class="back-item-subtitle">структурирование процесса менторинга в банке</p>
                </div>
                <div class="back-item">
                  <p class="back-item-title">Результат</p>
                  <p class="back-item-subtitle">появление единой платформы для обучения, возможности анализа — улучшение
                    качества менторинга на 18%</p>
                </div>
              </div>
            </div>
            <div class="business-options-blocks-item">
              <div class="front">
                <img src="../../assets/img/options2.svg" alt="">
                <p>Онбординг новых сотрудников в сфере девелопмента с привлечением менторов</p>

              </div>
              <div class="back gray">
                <div class="back-item">
                  <p class="back-item-title">Сфера</p>
                  <p class="back-item-subtitle">development</p>
                </div>
                <div class="back-item">
                  <p class="back-item-title">Запрос</p>
                  <p class="back-item-subtitle">онбординг новых сотрудников в сфере девелопмента с привлечением
                    менторов</p>
                </div>
                <div class="back-item">
                  <p class="back-item-title">Результат</p>
                  <p class="back-item-subtitle">на 23% снизилось количество увольнений новых сотрудников</p>
                </div>
              </div>
            </div>
            <div class="business-options-blocks-item">
              <div class="front">
                <img src="../../assets/img/options3.svg" alt="">
                <p>Повышение квалификации it специалистов в диджитал компании</p>
              </div>
              <div class="back pink">
                <div class="back-item">
                  <p class="back-item-title">Сфера</p>
                  <p class="back-item-subtitle">логистика</p>
                </div>
                <div class="back-item">
                  <p class="back-item-title">Запрос</p>
                  <p class="back-item-subtitle">повышение квалификации it специалистов в диджитал компании</p>
                </div>
                <div class="back-item">
                  <p class="back-item-title">Результат</p>
                  <p class="back-item-subtitle">за 3 месяца специалисты уровня junior стали middle разработчиками</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="business-rates">
          <h2 class="business-rates-title">базовые Тарифы</h2>
          <div class="business-rates-blocks">
            <div class="business-rates-blocks-item">
              <p>количество пользователей платформы</p>
              <ul>
                <li>от 1 до 49</li>
                <li>от 50 до 99</li>
                <li>от 100 до 499</li>
                <li>от 500 до 999</li>
                <li>от 1000</li>
              </ul>
            </div>
            <div class="business-rates-blocks-item">
              <p>Стоимость за одного пользователя, ₽/мес.</p>
              <ul>
                <li>1 999 ₽</li>
                <li>1 499 ₽</li>
                <li>999 ₽</li>
                <li>699 ₽</li>
                <li>350 ₽</li>
              </ul>
            </div>
          </div>
        </section>
        <div id="form" style="transform: translateY(-50px)"></div>
        <section class="business-form">
          <div class="business-form-left">
            <div>
              <h2 class="business-form-left-title">настроим платформу
                под вас</h2>
              <p class="business-form-left-subtitle">Мы предоставляем платформу по подписке или White Label на выбор для
                корпоративных клиентов</p>
            </div>
            <div>
              <p class="business-form-left-title-list">При необходимости: </p>
              <ul>
                <li>размещение на вашем хостинге;</li>
                <li>кастомизация визуальной части и функциональных возможностей;</li>
                <li>обслуживание и техническое сопровождение 24/7.</li>
              </ul>
            </div>
          </div>
          <div class="business-form-right">
            <p class="business-form-right-title">Запросить демо-доступ</p>
            <p class="business-form-right-success-message" v-if="success">Заявка успешно отправлена. Мы скоро свяжемся с вами!</p>
            <form @submit.prevent="submit()">
              <AtomInput
                name="Имя"
                v-model="form.name"
                :error="errors.name"
              />
              <AtomInput
                name="Номер телефона"
                v-model="form.phone"
                :error="errors.phone"
              />
              <div class="form-note"> Нажимая на кнопку вы принимаете условия
                <a href="/offer" rel="noopener noreferrer" target="_blank">Оферты</a>
                и <a href="/privacy" rel="noopener noreferrer" target="_blank">Политики Конфиденциальности</a>
              </div>
              <button type="submit" class="base-button big primary" >
                <span >продолжить</span>
                <span class="bg" ></span>
                <span class="slice slice-1"></span>
                <span class="slice slice-2"></span>
              </button>
              <div class="business-form-right-social-button">
                <a href="https://api.whatsapp.com/send?phone=79997779697" class="base-button outlined" >
                  <span>вотсап</span>
                  <img src="../../assets/img/send.svg" alt="">
                  <span class="bg"></span>
                  <span class="slice slice-1"></span>
                  <span class="slice slice-2"></span>
                </a>
                <a href="https://t.me/Gusi_lebedi_com" class="base-button outlined" >
                  <span>телеграм</span>
                  <img src="../../assets/img/send.svg" alt="">
                  <span class="bg"></span>
                  <span class="slice slice-1"></span>
                  <span class="slice slice-2"></span>
                </a>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { feedback } from '~/api/feedback'


const emit = defineEmits(['success', 'failure'])
const success = ref(false)


const title = 'Mentoring.digital — Платформа менторинга для сотрудников'
const description = 'Mentoring.digital  — платформа менторинга для сотрудников, которая позволяет реализовать эффективную программу наставничества, помогает компаниям раскрывать потенциал своих сотрудников и ускорить рост бизнеса'
const image = 'https://mentoring.digital/image.jpg'

const feedbackModalData = ref({
  type: 'business',
  isShow: false,
})

function onClick() {
  return  feedbackModalData.value.isShow = true
}

useHead({
  title: title
})

useServerSeoMeta({
  ogUrl: 'https://mentoring.digital/',
  description,
  ogDescription: description,
  twitterDescription: description,
  keywords: 'Mentoring.digital  — платформа менторинга для сотрудников, которая позволяет реализовать эффективную программу наставничества, помогает компаниям раскрывать потенциал своих сотрудников и ускорить рост бизнеса',
  ogImage: image,
  twitterImage: image,
  twitterCard: 'summary_large_image'
})

const {
  form,
  errors,
  submit,
  loading
} = useForm(feedback, async data => {
  form.value.name = '';
  form.value.phone = '';
  success.value = true

  setTimeout(() => {
    success.value = false
  }, 5000)
  emit('success', data, form.value)
}, async data => {
  emit('failure', data, form.value)
})
form.value.type = feedbackModalData.value.type
</script>

<style scoped lang="scss">
html,
body {
  overflow: hidden;
}
.ticker {
  margin-bottom: 100px;
  .items {
    flex-shrink: 0;
    white-space: nowrap;
  }

  .item {
    padding: 17px 18px;
    border-radius: 8px;
    border: 2px solid var(--color-elements-quantery);
    gap: 11px;
    transition: all 0.1s ease-in-out;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    width: auto;
    white-space: nowrap;

    display: inline-block;
    margin-right: 10px;

    a {
      text-decoration: none;
    }
  }

  .marquee {
    animation: scroll 30s linear infinite;
  }

  .ticker:hover .marquee {
    animation-play-state: paused;
  }

  @keyframes scroll {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(calc(-160% - 20px));
    }
  }

}
[data-theme="dark"] .business-modal-wrapper {
  background: rgba(255,255,255,.2);
  z-index: 999999;
}
.business {
  img {
    object-fit: contain;
  }

  section {
    margin-bottom: 130px;
  }

  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    border-radius: 30px;
    background-color: var(--color-bg-secondary);
    margin-bottom: 25px;

    &-title {
      margin-bottom: 24px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        font-size: 54px;
        font-weight: 900;
        line-height: 70px;
        letter-spacing: 1.25px;
        text-align: center;
        text-transform: uppercase
      }

      img {
        display: inline-block;
      }
    }

    &-subtitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.2);
    z-index: 9999999999;
  }
  &-modal {
    margin: 0 auto;
    background-color: var(--color-bg);
    padding: 32px;
    text-align: center;
    max-width: 450px;
    border-radius: 16px;

    .icon-close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 24px;
      color: var(--color-elements-tertiary);
      cursor: pointer;
    }

    h3 {
      font-size: 28px;
      font-weight: 900;
      line-height: 38px;
      margin-bottom: 32px;
    }

    .input-container {
      margin-bottom: 16px;
    }

    .input-container:last-child {
      margin-bottom: 32px;
    }

    .form-note {
      text-align: center;
      margin-bottom: 32px;
    }

    button {
      width: 100%;
    }
  }



  &-education,
  &-capability,
  &-tasks {
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;

      h2 {
        font-size: 36px;
        font-weight: 900;
        line-height: 48px;
        letter-spacing: 0;
        text-transform: uppercase;
      }
    }

    &-blocks {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 115px;
      padding: 30px;
      border-radius: 30px;
      border: 1px solid var(--color-elements-quantery);
      background-color: var(--color-accent-transparent);
      color: var(--color-elements-primary);

      p {
        font-size: 24px;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: 0;
        text-align: center;
      }

      span {
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0;
      }
    }
  }

  &-capability {
    &-title {
      p {
        text-transform: uppercase;
      }
    }

    &-blocks {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;
      flex-basis: calc(33.3333% - 6px);
      border: 1px solid var(--color-bg-tertiary);
      border-radius: 10px;
      padding: 30px 20px;
      height: auto;
      background-color: var(--color-bg);

    }
  }

  &-education {

    &-arrow {
      position: absolute;
      content: "";
      max-width: 120px;
      width: 100%;
    }

    &-content {
      display: flex;
      justify-content: space-between;

      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 300px;
        width: 100%;
        padding: 30px 30px 145px;
        border-radius: 30px;
        border: 1px solid var(--color-elements-quantery);
        height: 260px;
        span {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        p {
          font-size: 24px;
          font-weight: 800;
        }

        &:nth-child(1) {
          background-color: var(--color-education-one);
        }

        &:nth-child(3) {
          background-color: var(--color-education-three);
          .business-education-arrow {
            bottom: -75px;
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          background-color: var(--color-bg-tertiary);
          margin-top: 140px;
          .business-education-arrow {
            top: -110px;
          }
        }
      }

    }
  }

  &-screencast {
    padding: 60px 45px;
    background-color: var(--color-bg-secondary);
    border-radius: 23px;

    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
    }
  }

  &-options {
    &-blocks {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      &-item {
        width: 100%;
        flex-basis: 33.3333%;
        border-radius: 10px;
        padding: 0 0 40px;
        border: 1px solid var(--color-education-three);
        perspective: 1000px;
        position: relative;

        .front, .back {
          width: 100%;
          height: 100%;
          transition: .5s;
          backface-visibility: hidden;
        }

        .back {
          position: absolute;
          transform: rotateY(180deg);
          top: 0;
          padding: 40px 25px;
          border-radius: 10px;

          &-item {
            margin-bottom: 35px;

            &-title {
              margin-bottom: 15px;
            }

            &-subtitle {
              font-size: 20px;
              font-weight: 500;
              line-height: 27px;
            }
          }
        }

        .back.yellow {
          background-color: var(--color-options-cart-yellow);
        }

        .back.gray {
          background-color: var(--color-options-cart-gray);
        }

        .back.pink {
          background-color: var(--color-options-cart-pink);
        }

        &:hover .front {
          transform: rotateY(180deg);
        }

        &:hover .back {
          transform: rotateY(360deg);
        }

        img {
          margin-bottom: 40px;
        }

        p {
          font-size: 24px;
          font-weight: 800;
          padding: 0 10px;
        }
      }
    }
  }

  &-rates {
    &-blocks {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      &-item {
        flex-basis: 50%;
        padding: 40px 30px;
        border-radius: 30px;

        &:first-child {
          background-color: var(--color-education-one);
        }

        &:last-child {
          background-color: var(--color-rates-purple);
        }

        p {
          max-width: 425px;
          font-size: 24px;
          font-weight: 900;
          margin-bottom: 60px;
          text-transform: uppercase;
          line-height: 32px;
        }

        ul li {
          font-size: 24px;
          font-weight: 500;
          line-height: 35px;
        }
      }
    }
  }

  &-form {
    display: flex;
    gap: 120px;
    border: 1px solid var(--color-elements-tertiary);
    border-radius: 30px;
    padding: 40px 30px;

    p {
      text-transform: uppercase;
    }


    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 50%;

      div:last-child {
        transform: translateY(-35px);
      }

      &-title {
        font-size: 36px;
        font-weight: 900;
        line-height: 48px;
        margin-bottom: 18px;
        text-transform: uppercase;

        &-list {
          text-transform: none !important;
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          margin-bottom: 15px;

        }
      }

      ul {
        padding-left: 20px;

        li {
          list-style: disc;
        }
      }

      &-subtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-transform: none !important;
      }
    }

    &-right {
      flex-basis: 50%;

      &-title {
        font-size: 24px;
        font-weight: 900;
        line-height: 32px;
        margin-bottom: 32px;
      }
      &-success-message {
        color: var(--color-btn-success-bg);
        margin-bottom: 20px;
      }

      .form-note,
      .input-container {
        margin-bottom: 24px;
      }

      .base-button {
        width: 100%;
        margin-bottom: 0;
      }

      .base-button.big {
        margin-bottom: 37px;
      }

      &-social-button {
        display: flex;
        gap: 24px;

        a {
          flex-basis: 50%;

          span:first-child {
            margin-right: 8px;
          }

          img {
            z-index: 3;
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}

.business-rates-title,
.business-screencast-title,
.business-options-title {
  font-size: 36px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 50px;
}
@media screen and (max-width: 1340px) {
.business-education {
  &-content {
    flex-direction: column;
    align-items: center;
    &-item {
      &:first-child,
      &:nth-child(3){
        left: -200px;
      }
      &:nth-child(3){
        .business-education-arrow {
          top: -110px;
          right: 0;
          transform: rotate(160deg);
        }
      }
      &:nth-child(2),
      &:last-child {
        margin-top: 0;
        left: 120px;
        .business-education-arrow {
          top: -140px;
          left: 0;
        }
      }
    }
  }
}

}
@media screen and (max-width: 1200px) {
  .business-options {
    &-blocks-item {
      padding-bottom: 70px;
    }

    .back {
      &-item {
        margin-bottom: 10px;

        &-title {
          font-size: 16px;
          margin-bottom: 0;
        }

        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1076px) {
  .business {
    section {
      margin-bottom: 50px;
    }

    &-banner {
      &-title {
        p {
          font-size: 36px;
          gap: 15px;
        }

        img {
          height: 70px;
        }
      }

      &-subtitle {
        font-size: 20px;
      }
    }

    &-capability {
      p {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 811px) {
  .business {
    &-capability {
      &-block {
        flex-basis: calc(50% - 5px);
      }
    }

    &-form {
      flex-direction: column;
      gap: 20px;

      &-left {
        gap: 20px;

        div:last-child {
          transform: none;
        }

        &-title-list {
          font-size: 18px;
        }
      }

      &-right {
        .base-button {
          margin-bottom: 10px;
        }

        &-social-button {
          flex-direction: column;
          gap: 0;

        }
      }
    }

    &-tasks {
      &-block {
        p {
          font-size: 18px;
        }
      }
    }
  }
  .horiz-container {
    padding-left: 0;
    padding-right: 0;
  }
  main {
    padding-top: 0;
  }
  .business-banner {
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 80px;
    padding-bottom: 90px;
  }
  .business-banner h1 p {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .business-banner-title img {
    height: 34px;
  }
  .business-banner-title p:last-child {
    flex-direction: column;
  }
  .business-banner-subtitle {
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .business-banner .base-button {
    display: block;
    width: 100%;
    font-size: 16px !important;
    margin-top: 20px;
  }
  .business section,
  .business-tasks {
    padding-left: 15px;
    padding-right: 15px;
  }
  .business-tasks-blocks {
    gap: 16px;
  }
  .business-tasks-block {
    padding: 16px 12px;
    height: auto;
    border-radius: 15px;
  }
  .business-tasks-block img {
    width: 20px;
  }
  .business-tasks-block p {
    font-size: 16px;
    line-height: 20px;
  }
  .business-capability-title h2,
  .business-tasks-title h2 {
    text-align: left !important;
  }
  .business-capability-title,
  .business-tasks-title {
    align-items: flex-start;
  }
  .business-capability-blocks .business-capability-block {
    flex-basis: calc(50% - 6px);
    padding: 12px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .business-capability p {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  .business-capability-block img {
    width: 64px;
    margin-bottom: 30px;
  }
  .business-education-content {
    align-items: flex-start;
  }
  .business-education-content-item {
    width: 200px;
    height: 175px;
    padding: 20px;
    position: relative;
  }
  .business-education-content-item:nth-child(2n) {
    margin-left: auto;
  }
  .business-education-content-item span {
    font-size: 12px;
  }
  .business-education-content-item p {
    font-size: 16px;
    line-height: 20px;
  }
  .business-education-content-item:first-child:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -85px;
    width: 80px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("assets/img/line-education.svg");
  }
  .business-education-content-item:nth-child(2):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -85px;
    width: 80px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("assets/img/line-education2.svg");
    transform: rotate(165deg);
  }
  .business-education-content-item:nth-child(3):after {
    content: "";
    position: absolute;
    bottom: -95px;
    left: 0;
    width: 80px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(assets/img/line-education.svg);
    transform: rotate(85deg) scale(1, -1);
  }
  .business-screencast {
    padding: 24px 11px;
  }
  .business-screencast h2.business-screencast-title {
    font-size: 36px !important;
  }
  .business-options-blocks img {
    width: 100% !important;
  }
  .business-options-blocks-item {
    padding-bottom: 20px;
  }
  .business-options-blocks .back {
    padding: 24px 12px !important;
  }

  .business-rates-blocks p {
    font-size: 20px;
    line-height: 24px;
  }
  .business-rates-blocks-item {
    padding: 24px 12px !important;
  }
  .business-form {
    border: none;
    padding-top: 0;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .business-form-right-social-button {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
  .business-modal-wrapper {
    margin-top: 70px;
  }
}

@media screen and (max-width: 750px) {
  .ticker {
    overflow: hidden;
  }
  .business {
    &-options {
      &-blocks {
        flex-direction: column;

        img {
          width: 200px;
        }

        .back {
          padding: 25px;
        }
      }
    }
    &-education {
      &-content {
        gap: 20px;
        &-item {
          max-width: 100%;
          img {
            display: none;
          }
          &:first-child,
          &:nth-child(3){
            left: 0;
          }
          &:nth-child(3){
            .business-education-arrow {
              top: 0;
              right: 0;
              transform: rotate(160deg);
            }
          }
          &:nth-child(2),
          &:last-child {
            margin-top: 0;
            left: 0;
            .business-education-arrow {
              top: -140px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  h2 {
    font-size: 28px !important;
    line-height: 35px !important;
  }
  .business {
    &-banner {
      &-title {
        p {
          font-size: 16px;
          gap: 4px;
          line-height: 30px;
        }

        img {
          height: 45px;
        }
      }

      &-subtitle {
        font-size: 14px;
      }
    }

    &-capability,
    &-tasks {
      &-title {
        flex-direction: column;

        h2 {
          margin-bottom: 24px;
          text-align: center;
        }

        a {
          width: 100%;
        }
      }

      &-block {
        text-align: center;
        gap: 5px;

        h2 {
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    &-capability {
      &-block {
        flex-basis: 100%;
      }
    }

    &-rates {
      &-blocks {

        p {
          margin-bottom: 10px;
        }

        p,
        ul li {
          font-size: 16px;
          line-height: 20px;
        }

        ul li {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
  .ticker {
    .item {
      padding: 8px;
    }
  }
}
@media screen and (max-width: 400px) {
  .business {
    &-banner {
      &-title {
        p {
          font-size: 14px;

        }

        img {
          height: 35px;
        }
      }

      &-subtitle {
        font-size: 12px;
        margin-bottom: 15px;
      }
      .base-button {
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .business-rates-blocks-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
